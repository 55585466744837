import React from "react";
import Footer from "../../components/home/Footer";
import File from "../../components/documents/certificate.pdf";

const About = () => {
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = File;
    link.download = "Certificate.pdf";
    link.click();
  };
  return (
    <>
      <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-b from-black to-black/80 relative px-10 pt-[9vh]">
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
        >
          About BytFarm
        </h2>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Welcome to BytFarm, your premier destination for profitable crypto
          investment and mining solutions. At BytFarm, we are passionate about
          empowering individuals and businesses to harness the potential of
          cryptocurrency and blockchain technology.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Our Mission
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Our mission at BytFarm is to democratize access to crypto mining and
          investment opportunities. We aim to provide a transparent, secure, and
          user-friendly platform that allows anyone, regardless of their
          technical expertise, to participate in the crypto economy and earn
          passive income.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          What We Offer
        </h5>
        <ul data-aos="fade-right" className="list-decimal list-inside">
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              Cutting-edge Mining Equipment:
            </span>{" "}
            BytFarm offers access to top-of-the-line mining equipment, enabling
            users to mine a wide range of cryptocurrencies and maximize their
            earnings.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              Transparent Operations:
            </span>{" "}
            We believe in transparency and provide real-time analytics and
            reporting tools so that our users can track their investments and
            mining activities with full visibility.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">Security:</span> Your
            security is our top priority. BytFarm employs advanced encryption
            methods to safeguard your investments and personal data.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">Expert Support:</span>{" "}
            Our team of experienced professionals is dedicated to providing
            exceptional support and guidance to help you succeed in the dynamic
            world of crypto investment.
          </li>
        </ul>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Our Vision
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          At BytFarm, we envision a future where crypto mining and investment
          are accessible to everyone, driving financial inclusion and
          empowerment globally. We are committed to innovation, sustainability,
          and creating value for our users and stakeholders.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Get Started with BytFarm Today!
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Join the thousands of individuals and businesses who are already
          benefiting from BytFarm's crypto investment and mining solutions. Sign
          up today, choose your investment plan, and start earning passive
          income with BytFarm.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10 mb-10">
          Thank you for choosing BytFarm as your trusted partner in crypto
          investment and mining. We look forward to helping you achieve your
          financial goals!
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10 mb-10">
          Thank you for choosing BytFarm as your trusted partner in crypto
          investment and mining. We look forward to helping you achieve your
          financial goals!
        </p>
        <div className="w-full flex justify-center mb-10">
          <button
            onClick={downloadPdf}
            className="p-2 w-fit bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
          >
            View Certificate
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
